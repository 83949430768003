<template>
  <div id="app" :class="[{hideApp:!showApp},$i18n.locale,]">
    <header-box/>
    <!-- <div class="main-container" @click="removeMactive"> -->
    <div :class="['main-container',$route.params.detailSlug?'noScroll':'']">
      <loading />
      <router-view></router-view>
      <a class="nav-contact inner-link active" :class="[mail_show?'extend':'',inContact?'invisible':'']" href="#" data-notification-link="contact" data-scroll-class="0vh:active extend">
        <i class="la la-envelope "></i>
        <p class="h5">
          <!-- 有任何需求嗎? 請與我們聯絡 -->
          {{ $t('ui_askme') }}
        </p>
      </a>
      <webinarContact/>
      <integrityForm/>
      <search/>
      <footer-box/>

      <!-- <information/> -->
      <!-- <msg/> -->

    </div>
    <contact/>
    <edmForm/>
    <cookieSetting/>
  </div>
</template>


<style lang="less">
  #app{
    overflow-x: hidden;
  }
  .hideApp{
    display: none;
  }
  .main-container{
    height:100vh;
    height: 100dvh;
    // overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: 991px){
      margin-top: 52px!important;
      height: calc(100vh - 52px);
      height: calc(100dvh - 52px);
    }
  }
  .noScroll{
    overflow-y: hidden;
  }
</style>
<script>

// 30th週年用的置頂訊息，無後台，暫時不需要
// import msg from "./components/Msg.vue"

// 舊的cookie設定
// import information from './components/Information.vue'
// 新的cookie設定
import cookieSetting from "./components/CookieSetting.vue"


import headerBox from "./components/Header.vue"
import footerBox from "./components/Footer.vue"
import contact from "./components/Contact.vue"
import edmForm from "@/components/edmForm.vue"
import webinarContact from "./components/Webinar-contact.vue"
import search from "./components/Search.vue"
import loading from './components/Loading.vue'
import router from './router'
import { mapState,mapActions } from "vuex"
import { setMetaInfo } from "@/libs/js/metaInfo.js"
import { setMetaLang } from "@/libs/js/metaLang.js"
import integrityForm from './components/Integrity-contact.vue'
import $ from 'jquery'

export default {
  data() {
    return {
      shared_state: window.shared_state,
      mail_show: false,
      nowCateSlug: null,
      metaData:{},
    };
  },

  metaInfo() {
    return this.metaData;
  },

  components: {
    // "msg": msg,
    "header-box": headerBox,
    "footer-box": footerBox,
    "contact": contact,
    "edmForm": edmForm,
    "search": search,
    "loading": loading,
    "webinarContact": webinarContact,
    "integrityForm": integrityForm,
    // "information": information,
    "cookieSetting":cookieSetting,
  },

  computed: {
    ...mapState(['defaultMeta','showApp','productCate','solutionCate','newsCate']),

    inContact:function() {
      if (this.$route.name=='contact') return true;
      return false;
    }
  },

  methods: {
    ...mapActions(["storeRouterTo","checkLang", "checkSession","fetchProductCategory","fetchSolutionCategory","fetchNewsCategory"]),

    // removeMactive:function() {
    //   if ($('#app').hasClass('m-active')) $('#app').removeClass('m-active');
    // },

    findCate:function(arr) {
      return arr.category_slug === this.nowCateSlug;
    },

    setDynamicCategoryMeta(routeName, queryCate,type,siteName) {
      this.nowCateSlug = queryCate;

      if (this[routeName + 'Cate'] != null && queryCate != 'all') 
      {
        var found = this[routeName + 'Cate'].find( this.findCate);

        if (found != undefined) 
        {
          setMetaInfo( this, found.meta_title, undefined, found.meta_desc, found['thumb_' + routeName + '_category'], undefined,type,siteName);
        }
      }
      else
      {
        setMetaInfo( this, undefined, undefined, undefined, undefined );
      }
    },
  },

  mounted() {
    this.$themeInitNotification();
    this.$themeInitbgHolder();



    // 好像沒有必要
    // filter query keys 
    // var currentName = router.history.current.name;

    // if (currentName != 'product' && currentName != 'solution' && currentName != 'casestudy' && currentName != 'blog' && currentName != 'news' && currentName != 'distribution' && currentName != 'webinar') 
    // {
    //   if (window.location.href.includes('?')) 
    //   {
    //     if (currentName != 'search-result')
    //     {
    //       if (currentName != 'solution-detail' && currentName != 'casestudy-detail' && currentName != 'blog-detail' && currentName != 'news-detail' && currentName != 'csr-page' && currentName != 'product-detail') 
    //       {
    //         // delete all queries
    //         let newURL = window.location.href.split('?');
    //         window.location = newURL[0];
    //       }
    //     }
    //     else
    //     {
    //       // if query key != 'q', remove it
    //       let uri = window.location.search.substring(1);
    //       let params = new URLSearchParams(uri);

    //       for (var key of params.keys()) {
    //         if (key != 'q') 
    //         {
    //           var newURL = window.location.href.split('?');
    //           window.location = newURL[0];
    //         }
    //       }
    //     }
    //   }
    // }




    // this.$window.initAll();
    
    setTimeout(() => {
      this.mail_show = true;

      setTimeout(() => {
        this.mail_show = false;
      }, 3000);
    }, 1000);

    router.beforeEach((to, from, next) => {
      this.$toggleLoading(true);

      if ( to.matched[0].path != '*' ) $('meta[name="prerender-status-code"]').remove();

      if ($('#app').hasClass('m-active')) $('#app').removeClass('m-active');

      if (process.env.NODE_ENV === 'production') {
        if (to.meta.requireAuth) {
          this.checkSession({login:'/login',afterLogin:to.fullPath,routerNext:next});
        } else {
          next();
          setMetaLang( location.protocol +'//'+ location.host, to );
          if (to.query.cate != 'all'){
            if( to.name === 'product' || to.name === 'solution' || to.name === 'casestudy' || to.name === 'news' ) { // set meta
              // if(to.name === 'product'){
              //   this.setDynamicCategoryMeta( to.name, to.query.cate, 'product', false );
              // }else{
              //   this.setDynamicCategoryMeta( to.name, to.query.cate );
              // }
              this.setDynamicCategoryMeta( to.name, to.query.cate );
            } else {
              setMetaInfo( this, undefined, undefined, undefined, undefined );
            }
          }
        }
      } else { // yarn serve
        next();
        setMetaLang( location.protocol +'//'+ location.host, to );
        if (to.query.cate != 'all') {
          if ( to.name === 'product' || to.name === 'solution' || to.name === 'casestudy' || to.name === 'news' ) { // set meta
            // if(to.name === 'product'){
            //   this.setDynamicCategoryMeta( to.name, to.query.cate, 'product', false );
            // }else{
            //   this.setDynamicCategoryMeta( to.name, to.query.cate );  
            // }
            this.setDynamicCategoryMeta( to.name, to.query.cate );
          } else {
            setMetaInfo( this, undefined, undefined, undefined, undefined );
          }
        }
      }
    });

    router.afterEach((to, from) => {
      this.$nextTick(() => {
        this.$themeInitbgHolder();
      })
      
      // this.$initThemeJs();
      // ga('set', 'page', to.path);
      // ga('send', 'pageview');
    });
  },

  created() {
    this.checkLang();
    this.fetchProductCategory();
    this.fetchSolutionCategory();
    this.fetchNewsCategory();
    setMetaLang( location.protocol +'//'+ location.host, router.history.current);
    // setMetaInfo( this, undefined, undefined, undefined, undefined );
  }
};
</script>
