<template>
     <div id="webinarContactForm" class=" contact notification pos-bottom notification--reveal notification--dismissed m-0 p-0 h-100 " data-animation="from-bottom" data-notification-link="webinarContact" @mouseup="closePopup()">
        <div class="contact__table">
            <div class="contact__cell">
                <div  class="container contactBox col-10 col-sm-9 ml-auto mr-auto p-0">
                    <div class="feature feature-1 mb-0">
                        <div class="feature__body boxed boxed--lg boxed--border ">
                            <!-- <div class="text-block">
                                <div class="row">
                                    <h3 class="col-md-12 mb-1" v-html="$t('webinarContact_title')">Register On-Demand Webinar</h3>
                                    <p class="col-md-12 text-center" v-html="$t('webinarContact_subtitle')">Please fill in the form for waching videos.</p>
                                </div>
                            </div> -->
                            <h3 class="notification-title mb-0" v-html="$t('webinarContact_title')">Register On-Demand Webinar</h3>
                            <p class="text-left mt-0" v-html="$t('webinarContact_subtitle')">Please fill in the form for waching videos.</p>
                            <form id="webinarContactForm" class="text-left mt-4" @submit.prevent="sendFrom">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label v-html="$t('webinarContact_label_name')">姓名*</label>
                                        <input v-model="inputName" type="text" name="name">
                                    </div>
                                    <div class="col-md-6">
                                        <label v-html="$t('webinarContact_label_email')">電子郵件*</label>
                                        <input v-model="inputEmail" type="text" name="mail">
                                    </div>
                                    <div class="col-md-6">
                                        <label v-html="$t('webinarContact_label_company')">公司名稱*</label>
                                        <input v-model="inputCompany" type="text" name="company">
                                    </div>
                                    <div class="col-md-6">
                                        <label v-html="$t('webinarContact_label_job')">職稱*</label>
                                        <input v-model="inputJob" type="text" name="job">
                                    </div>
                                    <div class="col-md-6">
                                        <label v-html="$t('webinarContact_label_country')">國家*</label>
                                        <!-- <input v-model="inputCountry" type="text" name="contry"> -->
                                        <div class="input-select">
                                            <select v-model="inputCountry" v-if="countries">
                                                <option disabled selected v-html="$t('contact_select_country')">請選擇國家</option>
                                                <option v-for="country in countries">{{country.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label v-html="$t('webinarContact_label_city')">城市*</label>
                                        <input v-model="inputCity" type="text" name="city">
                                    </div>
                                    <!-- <div class="col-md-12 checkboxSet">
                                        <div class="input-checkbox">
                                            <input id="webinarInputAgreeInfo" type="checkbox" v-model="webinarInputAgreeInfo">
                                            <label for="webinarInputAgreeInfo" v-html="$t('contact_agree_info')">我願意接受信邦電子及其相關企業的資訊</label>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 checkboxSet">
                                        <div class="input-checkbox">
                                            <input id="webinarInputAgreePrivacy" type="checkbox" v-model="webinarInputAgreePrivacy">
                                            <label for="webinarInputAgreePrivacy" v-html="$t('webinarContact_agree_privacy')">送出表單同時代表您同意接收SINBON的最新訊息，如您日後不想再收到訊息，可自郵件中的連結取消訂閱</label>
                                        </div>
                                        <div class="input-checkbox">
                                            <input id="webinarInputAgreeSms" type="checkbox" v-model="webinarInputAgreeSms">
                                            <label for="webinarInputAgreeSms" v-html="$t('webinarContact_agree_sms')">送出表單同時代表您同意接收SINBON的最新訊息，如您日後不想再收到訊息，可自郵件中的連結取消訂閱</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                         <button type="submit" :disabled="sendIsDisabled" class="btn btn--primary type--uppercase" v-html="$t('webinarContact_label_register')">REGISTER</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="notification-close-cross notification-close" @click="setWebinarContactQuery(null)"></div>
                
                </div>  
            </div>
        </div>
    </div>
</template>
<script>

    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapActions, mapGetters } from "vuex";
    import { readCookie } from "@/libs/js/cookie.js";
    import {country} from "@/static/countryList.json";

    export default {
        data:function(){
            return{
                // props:['inquery','category','product','solution'],
                contacts:null,
                categories:null,
                currCategory:null,
                products:null,
                solutions:null,
                countries:country,

                inputCountry:'',
                inputCompany : '',
                inputName:'',
                inputCity:'',
                inputJob:'',
                inputEmail:'',

                // webinarInputAgreeInfo:false,
                webinarInputAgreePrivacy:false,
                webinarInputAgreeSms:false,
                sendIsDisabled:false
            }
        },
        methods:{
            ...mapActions(["setWebinarContactQuery","setLoadingStatus"]),
            ...mapGetters(["getWebinarContactQuery"]),
            closePopup:function(){
                var _this=this;
                $(document).mouseup(function(e){
                  var _con = $('.contactBox');   
                  if(!_con.is(e.target) && _con.has(e.target).length === 0){
                    $('#contactForm').addClass('notification--dismissed');
                    $('html').removeClass('popup');
                  }
                });
            },
            sendFrom(){
                var _this=this;

                if(_this.inputCountry.trim()==''){
                    alert(_this.$t('webinarContact_alert_1'));//請選擇國家
                    return;
                }
                if(_this.inputCity.trim()==''){
                    alert(_this.$t('webinarContact_alert_4'));//請填寫城市
                    return;
                }
                if(_this.inputCompany.trim()==''){
                    alert(_this.$t('webinarContact_alert_2'));//請填寫公司名稱
                    return;
                }
                if(_this.inputName.trim()==''){
                    alert(_this.$t('webinarContact_alert_3'));//請填寫姓名
                    return;
                }
                if(_this.inputJob.trim()==''){
                    alert(_this.$t('webinarContact_alert_5'));//請填寫職稱
                    return;
                }
                if(_this.inputEmail.trim()==''){
                    alert(_this.$t('webinarContact_alert_6'));//請填寫電子郵件
                    return;
                }
                var r=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!r.test(_this.inputEmail.trim().toLowerCase())){
                    alert(_this.$t('webinarContact_alert_7'));//請填寫正確的電子郵件格式
                    return;
                }
                if(_this.webinarInputAgreePrivacy==false){
                    alert(_this.$t('webinarContact_alert_9'));//請詳閱並同意隱私權政策
                    return;
                }
                
                var data={
                    'conutry': _this.inputCountry,
                    'city': _this.inputCity,
                    'company': _this.inputCompany,
                    'name': _this.inputName,
                    'email': _this.inputEmail,
                    'job': _this.inputJob,
                };
                data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
                data['lang']=readCookie('lang_code');
                
                _this.setLoadingStatus(true);
                var bodyFormData = new FormData();
                for(var k in data) bodyFormData.append(k,data[k]);
                this.sendIsDisabled='disabled';
                
                axios(apibase + 'webinarregister/register', {
                    method: 'post',
                    data: bodyFormData,
                    params: { lang: readCookie('lang_code') }
                })
                .then(function (response) {
                    // subscribe email
                    _this.subscribe();

                    // set cookie
                    $cookies.set( "webinarRegistered", true );
                    _this.$store.dispatch( 'setWebinarRegisterStatus' , true );

                    _this.inputCountry='';
                    _this.inputCity='';
                    _this.inputCompany='';
                    _this.inputName='';
                    _this.inputEmail='';
                    _this.inputJob='';
                    $('#webinarContactForm').addClass('notification--dismissed');
                    $('html').removeClass('popup');
                    alert(_this.$t('webinarContact_alert_8'));//送出成功，我們將儘快與您聯繫，謝謝您的洽詢
                    _this.sendIsDisabled=false;
                    _this.setLoadingStatus(false);
                    document.getElementById("webinarContactForm").reset();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            subscribe() {
                var _this = this;
                var data={
                    'email_subscribe': _this.inputEmail,
                };
                data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
                data['lang']=readCookie('lang_code');
                var bodyFormData = new FormData();
                for(var k in data) bodyFormData.append(k,data[k]);
                axios(apibase + "edm", {
                    method: "post",
                    data: bodyFormData,
                    params: { lang: readCookie('lang_code') }
                })
                .then(function(response) {
                    _this.inputEmail = "";
                })
                .catch(function(error) {
                console.log(error);
                });
            }

        },
        created() {
        },
        computed: {
            // ...mapState(['']),
            webinarContactQuery(){
                return this.getWebinarContactQuery();
            }
        },
        watch: {
            '$route' (to, from) {
                if(!$('#webinarContactForm').hasClass('notification--dismissed')) $('#webinarContactForm').addClass('notification--dismissed');
            },
        },
    };

</script>