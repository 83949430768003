<template>
    <div>
        <div class="cookieSetting">
            <div class="wrapper">
                <div class="content">
                    <h2 class="title h3">{{ $t('cookieSetting_title') }}</h2>
                    <!--
                    //========================================================================
                    //                                                                        
                    //  #####   ####   ####  #####  ##     ##  ######  ##    ###    ##      
                    //  ##     ##     ##     ##     ####   ##    ##    ##   ## ##   ##      
                    //  #####   ###    ###   #####  ##  ## ##    ##    ##  ##   ##  ##      
                    //  ##        ##     ##  ##     ##    ###    ##    ##  #######  ##      
                    //  #####  ####   ####   #####  ##     ##    ##    ##  ##   ##  ######  
                    //                                                                        
                    //========================================================================
                    -->
                    <div class="block first">
                        <div class="block__header">
                            <div class="block__switch">
                                <input id="essential" type="checkbox" v-model="cookies.essential" checked disabled>
                                <label for="essential"></label>
                            </div>
                            <div class="block__title">
                                <div>
                                    <h3>{{ $t('cookieSetting_block1_title') }}</h3>
                                </div>
                                <div>
                                    <div class="block__titleArrow"></div>
                                </div>
                            </div>
                        </div>
                        <div class="block__body">
                            <p>{{ $t('cookieSetting_block1_desc') }}</p>
                        </div>
                    </div>


                    <!--
                    //=======================================================================================
                    //                                                                                       
                    //  #####  ##   ##  ##     ##   ####  ######  ##   #####   ##     ##    ###    ##      
                    //  ##     ##   ##  ####   ##  ##       ##    ##  ##   ##  ####   ##   ## ##   ##      
                    //  #####  ##   ##  ##  ## ##  ##       ##    ##  ##   ##  ##  ## ##  ##   ##  ##      
                    //  ##     ##   ##  ##    ###  ##       ##    ##  ##   ##  ##    ###  #######  ##      
                    //  ##      #####   ##     ##   ####    ##    ##   #####   ##     ##  ##   ##  ######  
                    //                                                                                       
                    //=======================================================================================
                    -->
                    <div class="block">
                        <div class="block__header">
                            <div class="block__switch">
                                <input id="functionality" type="checkbox" v-model="cookies.functionality" checked>
                                <label for="functionality"></label>
                            </div>
                            <div class="block__title">
                                <div>
                                    <h3>{{ $t('cookieSetting_block2_title') }}</h3>
                                </div>
                                <div>
                                    <div class="block__titleArrow"></div>
                                </div>
                            </div>
                        </div>
                        <div class="block__body">
                            <p>{{ $t('cookieSetting_block2_desc') }}</p>
                        </div>
                    </div>

                    <!--
                    //=========================================================================
                    //                                                                         
                    //   ####  ######    ###    ######  ##   ####  ######  ##   ####   ####  
                    //  ##       ##     ## ##     ##    ##  ##       ##    ##  ##     ##     
                    //   ###     ##    ##   ##    ##    ##   ###     ##    ##  ##      ###   
                    //     ##    ##    #######    ##    ##     ##    ##    ##  ##        ##  
                    //  ####     ##    ##   ##    ##    ##  ####     ##    ##   ####  ####   
                    //                                                                         
                    //=========================================================================
                    -->
                    <div class="block last">
                        <div class="block__header">
                            <div class="block__switch">
                                <input id="statistics" type="checkbox" v-model="cookies.statistics" checked>
                                <label for="statistics"></label>
                            </div>
                            <div class="block__title">
                                <div>
                                    <h3>{{ $t('cookieSetting_block3_title') }}</h3>
                                </div>
                                <div>
                                    <div class="block__titleArrow"></div>
                                </div>
                            </div>
                        </div>
                        <div class="block__body">
                            <p>{{ $t('cookieSetting_block3_desc') }}</p>
                        </div>
                    </div>
                    

                    <div class="btnGroup">
                        <button class="btn btn--primary" @click="saveSetting"><span class="btn__text">{{ $t('cookieSetting_btn_save') }}</span></button>
                        <button class="btn btn--primary" @click="acceptAll"><span class="btn__text">{{ $t('cookieSetting_btn_accept_all') }}</span></button>
                    </div>
                </div>  
            </div>
        </div>



        <div class="cookieTip">
            <div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div class="cookieTip__content" v-html="$t('cookieTip_content')"></div>
                <div class="cookieTip__btns d-flex align-items-center mt-3 mt-md-0">
                    <button class="btn btn--primary bg--dark ml-md-4 my-0" @click="showCookieSetting">{{ $t('cookieTip_btn_setting') }}</button>
                    <button class="btn btn--primary ml-4 my-0" @click="acceptAll">{{ $t('cookieTip_btn_accept') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .cookieTip{
        position: fixed;
        z-index: 9999;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(25, 25, 25, 0.95);
        // border-radius: 10px;
        color: white;
        display: none;
        &.active{
            display: block;
        }
        .container{
            max-width: 850px;
            padding: 1rem;
        }
        .cookieTip__btns{
            width: auto;
            .btn{
                min-width:80px;
                padding-left:1rem;
                padding-right:1rem;
            }
        }
    }
    .cookieTip__content{
        flex-grow: 1;
    }

    .cookieSetting{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color:rgba(24, 58, 95, 0.95);
        display: none;
        justify-content: center;
        align-items: center;
        &.active{
            display: flex;
        }
        .wrapper{
            width: 60%;
            max-height: 80%;
            background-color: #fff;
            padding: 30px;
            padding-bottom: 35px;
            border-radius: 10px;
            overflow: auto;
        }
        @media only screen and (max-width: 991px) {
            .wrapper{
                width: 80%;
            }
        }
        @media only screen and (max-width: 767px) {
            .wrapper{
                width: 95%;
                height: 95%;
                max-height: 95%;
            }
            .btnGroup{
                gap:10px;
            }
        }
        .block{
            border: 1px solid #ccc;
            border-top: none;
            .block__body{
                display: none;
            }
            &.active{
                .block__body{
                    display: block;
                }
                .block__titleArrow{
                    transform: rotate(135deg) translateY(-2px);
                }
            }
            &.first{
                border-top: 1px solid #ccc;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &.last{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                margin-bottom: 40px;
            }
        }
        .block__header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .block__title{
            padding: 20px 20px;
            padding-left: 0;
            cursor: pointer;
            flex:1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover{
                h3{
                    color: black;
                }
                .block__titleArrow{
                    border-color: black;
                }
            }
            h3{
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
        .block__titleArrow{
            width: 9px;
            height: 9px;
            border-left: 1px solid #666;
            border-bottom: 1px solid #666;
            transform: rotate(-45deg) translateY(-2px);
            transform-origin: center;
            margin-left:15px;
            transition: all 0.3s;
        }
        .block__body{
            padding:15px 20px;
            border-top: 1px solid #ccc;
        }
        .block__switch{
            display: flex;
            align-items: center;
            flex:0 0 60px;
            justify-content: center;
            transform: scale(0.8);
            &.part{
                label{
                    background-color: #b4bdc5;
                    &::after{
                        display: block;
                    }
                }
            }
            input{
                opacity: 0;
                width: 0;
                height: 0;
                appearance: none;
                padding: 0;
                &:checked+label{
                    background-color: #2ab2de;
                    // &::before,
                    &::after{
                        display: block;
                    }
                }
                &:disabled + label{
                    opacity: 0.5;
                    cursor: default;
                }
            }
            label{
                display: inline-block;
                width: 24px;
                height: 24px;
                background-color: #f3f3f3;
                outline:1px #eee solid;
                border-radius: 99px;
                position: relative;
                cursor: pointer;
                transition: all 0.3s;
                &::after{
                    display: none;
                    content: '';
                    position: absolute;
                    top:4px;
                    left:10px;
                    width: 6px;
                    height: 13px;
                    border:2px solid #fff;
                    border-top:none;
                    border-left: none;
                    transform: rotate(45deg);
                }
            }
        }
        .btnGroup{
            display: flex;
            .btn{
                flex: 1;
            }
        }
    }
</style>


<script>
export default {
    name: 'cookieSetting',
    mounted(){
        this.$nextTick(() => {
            document.querySelectorAll('.cookieSetting .block__switch input').forEach((input) => {
                input.addEventListener('change', (e) => {
                    this.toggleAllItemSwitch(e.target.closest('.block'));
                });
            });
            document.querySelectorAll('.cookieSetting .block__title').forEach((title) => {
                title.addEventListener('click', (e) => {
                    this.toggleCollapse(e.target.closest('.block'));
                });
            });

            if(this.$cookieSetting){
                // _cookieSetting=JSON.parse(_cookieSetting);
                for(let key in this.$cookieSetting){
                    this.cookies[key] = this.$cookieSetting[key];
                }
                this.hideCookieTip();
                this.hideCookieTip();
                this.initFuncs();
            }else{
                this.showCookieTip();
            }
        });
    },
    methods:{
        toggleCollapse(block){
            block.classList.toggle('active');
        },
        saveSetting(){
            localStorage.setItem('cookieSetting', JSON.stringify(this.cookies));
            this.updateConsent();
            this.hideCookieSetting();
        },
        acceptAll(){
            for(let key in this.cookies){
                this.cookies[key] = true;
            }
            localStorage.setItem('cookieSetting', JSON.stringify(this.cookies));
            this.updateConsent();
            this.initFuncs();
            this.hideCookieSetting();
            this.hideCookieTip();
        },



        showCookieSetting(){
            document.querySelector('.cookieTip').classList.remove('active');
            document.querySelector('.cookieSetting').classList.add('active');
        },
        hideCookieSetting(){
            document.querySelector('.cookieSetting').classList.remove('active');
        },
        
        showCookieTip(){
            document.querySelector('.cookieTip').classList.add('active');
        },
        hideCookieTip(){
            document.querySelector('.cookieTip').classList.remove('active');
        },

        initFuncs(){
            if(this.cookies.statistics&&!this.added_ga) this.loadGTM();
            if(this.cookies.statistics&&!this.added_linkedin) this.loadLinkedin();
        },

        loadGTM() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'wait_for_update': 500
            });

            const script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=GTM-M44JDBF';
            script.async = true;
            document.head.appendChild(script);

            gtag('js', new Date());
            gtag('config', 'GTM-M44JDBF');
            
            this.added_ga = true;
        },
        loadLinkedin(){
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.text = `
            _linkedin_partner_id = "2481188";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            `;
            script.async = true;
            document.body.appendChild(script);


            const script2 = document.createElement('script');
            script2.type = 'text/javascript';
            script2.text = `
            (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`;
            script2.async = true;
            document.body.appendChild(script2);

            this.added_linkedin = true;
            //<noscript><img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2481188&fmt=gif" /></noscript>
        },
        updateConsent() {
            if (!window.dataLayer) return;
            
            function gtag(){dataLayer.push(arguments);}
            
            gtag('consent', 'update', {
                'ad_storage': this.cookies.statistics ? 'granted' : 'denied',
                'analytics_storage': this.cookies.statistics ? 'granted' : 'denied',
                'ad_user_data': this.cookies.statistics ? 'granted' : 'denied',
                'ad_personalization': this.cookies.statistics ? 'granted' : 'denied'
            });
        },
    },
    data: function() {
        return {
            cookies:{
                essential:true,
                functionality:true,
                statistics:true,
            },
            added_ga:false,
            added_linkedin:false,
        };
    },
};
</script>