import store from '../../store/index';

// function setMetaInfo(title,url,desc,img,keywords){
function setMetaInfo( obj, title, url, desc, img, type, siteName ){    
    obj.metaData.meta = [];
    obj.metaData.link = [];

    let _siteName=' | ' + SETTINGS.site_name;
    if(siteName===false){
        _siteName='';
    }

    if(type!=undefined&&type!=''){
        document.head.querySelector('meta[property="og:type"]').content = type;
    }

    if(title!=undefined && title!=''){
        // html
        document.title = title +_siteName;
        document.head.querySelector('meta[property="og:title"]').content = title +_siteName;
        document.head.querySelector('meta[name="twitter:title"]').content = title +_siteName;
        
        // vue-meta
        obj.metaData.title = title;
        // obj.metaData.titleTemplate = '%s | ' + SETTINGS.site_name;
        obj.metaData.titleTemplate = '%s'+_siteName;
    }else{
        // html
        document.title = store.getters.getAllstate.defaultMeta.title;
        document.head.querySelector('meta[property="og:title"]').content = store.getters.getAllstate.defaultMeta.title;
        document.head.querySelector('meta[name="twitter:title"]').content = store.getters.getAllstate.defaultMeta.title;
        // console.log('store.getters.getAllstate.defaultMeta.title',store.getters.getAllstate.defaultMeta.title);
        
        // vue-meta
        obj.metaData.title = store.getters.getAllstate.defaultMeta.title;
        // obj.metaData.titleTemplate = '%s | ' + SETTINGS.site_name;
        obj.metaData.titleTemplate = '%s'+_siteName;
    }

    // Fix robots cannot index has gtld page issue. @CLHOM
    /*// set canonical
    let canonicalUrl
    if( obj.$route.query.page && obj.$route.query.page !=1 ) canonicalUrl = window.location.href.slice(0,-1)+'1'
    else canonicalUrl = window.location.href

    if(canonicalUrl.indexOf('?category')!=-1) canonicalUrl = canonicalUrl.slice(0,canonicalUrl.indexOf('?category'));

    let canonicalObj = document.head.querySelector("link[rel=canonical]");
    if (!canonicalObj) {
        // 第一次進入頁面, 沒有 canonical
        $("head").append( '<link rel="canonical" href="' + canonicalUrl +'">' )
    }else{
        document.head.querySelector("link[rel=canonical]").href = canonicalUrl;
    }*/
    
    if(url!=undefined){
        // html
        document.head.querySelector('meta[property="og:url"]').content = url;
    }else{
        // html
        document.head.querySelector('meta[property="og:url"]').content = window.location.href;
    }
    
    if (desc != undefined && desc != '' && desc != 'none') {
        // html
        document.head.querySelector('meta[name="description"]').content = desc;
        document.head.querySelector('meta[property="og:description"]').content = desc;
        document.head.querySelector('meta[name="twitter:description"]').content = desc;
    } else if( desc === 'none' ) { 
        document.head.querySelector('meta[name="description"]').content = '';
        document.head.querySelector('meta[property="og:description"]').content = '';
        document.head.querySelector('meta[name="twitter:description"]').content = '';
    }
    else {
        // html
        document.head.querySelector('meta[name="description"]').content = store.getters.getAllstate.defaultMeta.description;
        document.head.querySelector('meta[property="og:description"]').content = store.getters.getAllstate.defaultMeta.description;
        document.head.querySelector('meta[name="twitter:description"]').content = store.getters.getAllstate.defaultMeta.description;
    }
    if(img!=undefined && img != 'none') {
        // html
        document.head.querySelector('meta[property="og:image"]').content = img;
        document.head.querySelector('meta[name="twitter:image"]').content = img;
    } else if (img === 'none') { 
        document.head.querySelector('meta[property="og:image"]').content = '';
        document.head.querySelector('meta[name="twitter:image"]').content = '';
    } else {
        // html
        document.head.querySelector('meta[property="og:image"]').content = store.getters.getAllstate.defaultMeta.img;
        document.head.querySelector('meta[name="twitter:image"]').content = store.getters.getAllstate.defaultMeta.img;
    }
    // if(keywords!=undefined){
    //     document.head.querySelector('meta[name="keywords"]').content = keywords;
    // }else{
    //     document.head.querySelector('meta[name="keywords"]').content = store.getters.getAllstate.defaultMeta.keywords;
    // }
    // obj.$meta().refresh();
}

export { setMetaInfo };