<template>
	<div class="sustainabilityReport">
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-sustainability_report.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('nav_sustainability_report')">永續報告書</h1>
                        <!-- <p v-html="$t('csr_download_banner_p')">這些價值導引我們的經營運作，並時時提醒我們成功的要素為何</p> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <!-- <li v-html="$t('nav_csr')">企業與社會責任</li> -->
                    <li v-html="$t('nav_sustainability_report')">永續報告書</li>
                </ol>
            </div>
        </section>
        <!-- <section>
            <div class="container text-left">
                <div class="row">
                    <div class="col">
                        <h3 class="m-0" v-html="$t('csr_download_section_title')">企業社會責任報告書</h3>
                    </div>
                </div>
            </div>
        </section> -->
        <section v-if="items">
            <div class="container text-center">
                <div class="row">
                    <div :class="idx==0?'col-md-12':'col-md-4'" v-for="(item,idx) in items.rows">
                        <div class="feature feature-3 boxed boxed--lg boxed--border" :class="idx==0?'firstItem':''" style="background:#fcfcfc;">
                            <div class="cover">
                                <img :src="item.cover" :alt="item.name"/>
                            </div>
                            <div class="content mt-3">
                                <h4 class="reportTitle">{{item.name}}</h4>
                                <!-- <a class="btn btn--primary mt-3" :href="item.file" target="_blank"><span class="btn__text" v-html="$t('ui_download')">下載PDF</span></a> -->
                                <div class="btns">
                                    <a v-if="idx==0&&item.summary_file" class="btn btn--primary mt-3" :href="item.summary_file" target="_blank"><span class="btn__text" v-html="$t('ui_summary')">摘要</span></a>
                                    <a class="btn btn--primary mt-3" :href="item.file" target="_blank"><span class="btn__text" v-html="$t('ui_download')">下載PDF</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style>
.sustainabilityReport{
    .feature img{
        box-shadow: 0 5px 20px 0px rgba(0,0,0,0.1);
    }
    .firstItem{
        display: flex;
        align-items: center;
        gap:40px;
        .cover{
            flex:0 0 40%;
            margin-bottom: 0;
            img{
                width: 100%;
            }
        }
        .content{
            flex:0 0 60%;
            text-align: left;
            margin-top: 0!important;
        }
        .reportTitle{
            font-size: 48px;
            line-height: 1.2;
            font-weight: 300;
        }
        @media screen and (max-width: 1300px){
            .cover{
                flex:0 0 calc(100% - 420px);
            }
            .content{
                flex:0 0 380px;
            }
        }
        @media screen and (max-width: 1200px){
            gap:30px;
            .reportTitle{
                font-size: 3vw;
            }
        }
        @media screen and (max-width: 1024px){
            .cover{
                flex:0 0 calc(100% - 380px);
            }
            .content{
                flex:0 0 350px;
            }
            .reportTitle{
                font-size: 24px;
            }
        }
        @media screen and (max-width: 992px){
            .cover{
                flex:0 0 calc(100% - 430px);
            }
            .content{
                flex:0 0 400px;
            }
            .reportTitle{
                font-size: 32px;
            }
        }
        @media screen and (max-width: 767px){
            flex-direction: column;
            gap:0;
            margin-bottom: 4rem!important;
            .cover{
                flex:0 0 100%;
            }
            .content{
                flex:0 0 100%;
                margin-top: 20px!important;
            }
            .reportTitle{
                font-size: 32px;
                text-align: center;
            }
            .btns{
                display: flex;
                gap:10px;
                justify-content: center;
                a{
                    margin-bottom: 0;
                }
            }
        }
        @media screen and (max-width: 540px){
            .reportTitle{
                font-size: 5vw;
                font-weight: 400;
            }
            .btns{
                flex-direction: column;
                gap: 0;
            }
        }
    }
    @media screen and (max-width: 992px){
        section{
            overflow: visible!important;
        }
        .col-md-12{
            position: relative;
            &::after{
                content: '';
                position: absolute;
                left: -5vw;
                top:-4rem;
                width: calc(100% + 10vw);
                height: calc(100% + 7rem);
                background: #a8bed3;
                z-index: -1;
            }
        }
    }
    @media screen and (max-width: 1200px){
        .boxed.boxed--lg{
            padding: 20px!important;
        }
    }
}

</style>
<script>
import apibase from "@/static/apibase.js";
import axios from 'axios';
// import {readCookie} from "@/libs/js/cookie.js"
import {setMetaInfo} from "@/libs/js/metaInfo.js";
export default {
    data:function(){
        return{
            items:null,
            metaData:{}
        }
    },
    metaInfo() {
        return this.metaData
    },
    methods:{
        fetchItems:function(){
            var _this=this;
            // 
            axios({
                method: 'get',
                baseURL: apibase,
                url: 'csrreport/items',
                
            })
            .then(function (response) {
                // console.log(response);
                _this.items=response.data;
                _this.$toggleLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    },
    created(){
        this.fetchItems();
        setMetaInfo( this, this.$t('nav_sustainability_report'), undefined, undefined, undefined )
    }
};
</script>