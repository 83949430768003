<template>
	<div>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h1 class="h2 mb-1">{{ $t('smsNotice_title') }}</h1>
                        <small class="type--fine-print">{{ $t('smsNotice_date') }}</small>
                        <hr class="short">
                        <p class="sub-lead">{{ $t('smsNotice_param_1') }}</p>
                        <p class="sub-lead">{{ $t('smsNotice_param_2') }}</p>
                        <p class="sub-lead" v-html="$t('smsNotice_param_3')"></p>
                        <p class="sub-lead">{{ $t('smsNotice_param_4') }}</p>
                        <p class="sub-lead">{{ $t('smsNotice_param_5') }}</p>
                        <p class="sub-lead">{{ $t('smsNotice_param_6') }}</p>
                        <p v-for="item in $t('smsNotice_param_contact')" class="sub-lead mb-1" v-html="item"></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            setMetaInfo( this,this.$t('smsNotice_title') , undefined, undefined, undefined )
            // this.$window.initAll();
            this.$toggleLoading(false);
        }
    };

</script>