<template>
	<div class="job">
		<section class="py-5">
            <div class="container">
                <h2 v-html="info" class="cms-content my-0"></h2>
            </div>
        </section>

        <section class="bg--secondary py-5" v-if="sapJobLoaded" style="min-height: 500px;">
            <div class="container py-3">
                <div v-if="sapJobs.length">
                    <div class="masonry-filter">
                        <div class="masonry-filter-header">
                            <h2 class="h3 mb-0">{{$t('sap_filter_title')}}</h2>
                            <a href="#">{{$t('sap_filter_btn')}}</a>
                        </div>
                        <div class="masonry-filter-bg"></div>
                        <div class="masonry-filter-group">
                            <div>
                                <span>{{ $t('sap_title_category') }}</span>
                                <div class="masonry-filter-holder">
                                    <div class="masonry__filters">
                                        <ul>
                                            <li @click="updateJobs($event)" data-filter="category" data-filter-value="" :class="!filter.category.value?'active':''">{{ $t('sap_filter_all') }}</li>
                                            <li v-for="item in filter.category.options" @click="updateJobs($event)" data-filter="category" :data-filter-value="item" :class="filter.category.value==item?'active':''">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <span>{{ $t('sap_title_location') }}</span>
                                <div class="masonry-filter-holder">
                                    <div class="masonry__filters">
                                        <ul>
                                            <li @click="updateJobs($event)" data-filter="location" data-filter-value="" :class="!filter.location.value?'active':''">{{ $t('sap_filter_all') }}</li>
                                            <li v-for="item in filter.location.options" @click="updateJobs($event)" data-filter="location" :data-filter-value="item" :class="filter.location.value==item?'active':''">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <span>{{ $t('sap_title_type') }}</span>
                                <div class="masonry-filter-holder">
                                    <div class="masonry__filters">
                                        <ul>
                                            <li @click="updateJobs($event)" data-filter="type" data-filter-value="" :class="!filter.type.value?'active':''">{{ $t('sap_filter_all') }}</li>
                                            <li v-for="item in filter.type.options" @click="updateJobs($event)" data-filter="type" :data-filter-value="item" :class="filter.type.value==item?'active':''">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="mt-4">
                        <table v-if="sapJobFiltered.length" class="jobTable">
                            <thead>
                                <tr>
                                    <th>{{ $t('sap_title_title') }}</th>
                                    <th>{{ $t('sap_title_category') }}</th>
                                    <th>{{ $t('sap_title_type') }}</th>
                                    <th>{{ $t('sap_title_location') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                class="jobTable__row"
                                v-for="item,idx in sapJobFiltered"
                                :data-id="item.jobReqId"
                                @click.prevent="viewJob(item)"
                                >
                                    <td>{{item.jobReqLocale.results[0].externalTitle}}</td>
                                    <td>{{ filterArrByLocale(item.cust_jobCategory.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</td>
                                    <td>{{ filterArrByLocale(item.partFullTime.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</td>
                                    <td>{{ item.sfstd_jobLocationShortFormat_obj['name_'+convertLocale()] }}</td>
                                    <td><a href="#" @click.prevent="viewJob(item)">{{ $t('ui_view') }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 v-else>{{ $t('sap_no_job') }}</h3>
                    </div>
                </div>
                <h3 v-else>{{ $t('sap_no_job') }}</h3>
            </div>
        </section>



        <Subpage :action="{text:$t('sap_btn_apply'),action:'apply'}" @action="subpageActioned" ref="subpage">
            <div class="container jobDetail" v-if="currJobItem!=null">
                <h3>{{ currJobItem.jobReqLocale.results[0].externalTitle }}</h3>
                <div class="jobDetail__item">
                    <p><strong>{{ $t('sap_title_location') }}：</strong>{{ currJobItem.sfstd_jobLocationShortFormat_obj['name_'+convertLocale()] }}</p>
                    <p><strong>{{ $t('sap_title_category') }}：</strong>{{ filterArrByLocale(currJobItem.cust_jobCategory.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p><strong>{{ $t('sap_title_department') }}：</strong>{{ currJobItem.cust_ParentOrg }}</p>
                    <p><strong>{{ $t('sap_title_type') }}：</strong>{{ filterArrByLocale(currJobItem.partFullTime.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p><strong>{{ $t('sap_title_travel') }}：</strong>{{ filterArrByLocale(currJobItem.travel.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p><strong>{{ $t('sap_title_exp') }}：</strong>{{ filterArrByLocale(currJobItem.workExperience.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p><strong>{{ $t('sap_title_edu') }}：</strong>{{ filterArrByLocale(currJobItem.mfield2.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p><strong>{{ $t('sap_title_degree') }}：</strong>{{ filterArrByLocale(currJobItem.mfield1.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p v-if="currJobItem.engSkill.results.length"><strong>{{ $t('sap_title_en') }}：</strong>{{ filterArrByLocale(currJobItem.engSkill.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p v-if="currJobItem.jpSkill.results.length"><strong>{{ $t('sap_title_jp') }}：</strong>{{ filterArrByLocale(currJobItem.jpSkill.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <!--<p v-if="currJobItem.spnSkill.results.length"><strong>{{ $t('sap_title_spn') }}：</strong>{{ filterArrByLocale(currJobItem.spnSkill.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>
                    <p v-if="currJobItem.otherLangSkill.results.length"><strong>{{ $t('sap_title_other_lang') }}：</strong>{{ filterArrByLocale(currJobItem.otherLangSkill.results[0].picklistLabels.results,'locale',$i18n.locale)['label'] }}</p>-->
                </div>
                
                <div v-html="currJobItem.jobReqLocale.results[0].externalJobDescription"></div>
            </div>
        </Subpage>
    </div>
</template>






<style>
.job{
    .masonry-filter{
        position: relative;
    }
    .masonry-filter-header{
        display: none;
    }
    .masonry-filter-bg{
        display: none;
    }
    .masonry-filter-group{
        display:flex!important;
        gap:25px;
        height: auto;
        margin: 0;
        >div{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap:5px;
            .masonry-filter-holder{
                width: auto;
                margin: 0;
                height: 48px!important;
                .masonry__filters{
                    min-width: auto;
                    width: 100%;
                    padding: 0.92857143em;
                }
                &:hover{
                    .masonry__filters{
                        max-height: 250px;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
    /* .masonry__filters{
        ul{
            max-height: 250px;
            overflow-y: auto;
        }
    } */
    @media screen and (max-width: 767px){
        .masonry-filter{
            &.active{
                .masonry-filter-bg{
                    display: block;
                }
                .masonry-filter-group{
                    display: flex!important;
                }
            }
        }
        .masonry-filter-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .masonry-filter-bg{
            display: none;
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(255,255,255,0.5);
            z-index: 100;
        }
        .masonry-filter-group{
            position: absolute;
            z-index: 101;
            left: 0;
            top: 0;
            display: none!important;
            flex-direction: column;
            gap: 20px;
            border-radius: 6px;
            padding: 1em;
            padding-bottom: 1.5em;
            background: white;
            box-shadow: 0 5px 30px rgba(0,0,0,0.15);
            border: 1px solid #ddd;
            >div{
                .masonry-filter-holder{
                    height: 36px!important;
                }
            }
        }
    }
    .jobTable{
        border-collapse: collapse;
        border:1px solid #ccc;
        th{
            white-space: nowrap;
            background: #f1f1f1;
        }
        td{
            background: none;
            border-top:1px solid #ccc!important;
            a{
                white-space: nowrap;
            }
        }
        .jobTable__row{
            cursor: pointer;
            background: white;
        }
    }
    @media screen and (min-width: 768px){
        .jobTable{
            .jobTable__row{
                &:hover{
                    background: #f9f9f9!important;
                    td{
                        color: #111;
                        border-top:1px solid #ccc!important;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px){
        .jobTable{
            border:none;
            thead{
                display: none;
            }
            .jobTable__row{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: 1em;
                border:1px solid #ececec;
                border-radius: 5px;
                padding: 1em;
                td{
                    flex: 0 0 100%;
                    display: block;
                    border: none!important;
                    padding: 0;
                    font-size: 0.875em;
                    &:first-child{
                        margin-bottom: 0.2em;
                        font-size: 1.25em;
                        color: #7ca2c9;
                    }
                    &:nth-child(2){
                        flex: 0 0 48%;
                        &::before{
                            content: '職位類別：';
                            font-weight: bold;
                        }
                    }
                    &:nth-child(3){
                        flex: 0 0 48%;
                        &::before{
                            content: '正式/临时：';
                            font-weight: bold;
                        }
                    }
                    &:nth-child(4){
                        &::before{
                            content: '工作地點：';
                            font-weight: bold;
                        }
                    }
                    &:last-child{
                        display: none;
                    }
                }
            }
        }
        
    }
    .jobDetail{
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .jobDetail__item{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2em;
        p{
            flex: 0 0 50%;
            margin-bottom: 0.5em;
        }
        @media screen and (max-width: 767px){
            p{
                flex: 0 0 100%;
            }
        }
    }
}
</style>








<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    import Subpage from "@/components/Subpage.vue";

	export default {
        components: {
            Subpage,
        },
        data:function(){
            return{
                info:null,
                
                sapJobLoaded:false,
                sapJobs:null,
                currJobItem:null,

                filter:{
                    type:{
                        options:[],
                        value:'',
                    },
                    category:{
                        options:[],
                        value:'',
                    },
                    location:{
                        options:[],
                        value:'',
                    },
                },

                metaData:{},
            }
        },
        metaInfo() {
            return this.metaData
        },
        computed:{
            sapJobFiltered(){
                var _this=this;
                if(this.filter.type.value||this.filter.category.value||this.filter.location.value){
                    return this.sapJobs.filter(function(item){
                        if(_this.filter.type.value&&_this.filterArrByLocale(item.partFullTime.results[0].picklistLabels.results,'locale',_this.$i18n.locale)['label']!=_this.filter.type.value) return false;
                        if(_this.filter.category.value&&_this.filterArrByLocale(item.cust_jobCategory.results[0].picklistLabels.results,'locale',_this.$i18n.locale)['label']!=_this.filter.category.value) return false;
                        if(_this.filter.location.value&&item.sfstd_jobLocationShortFormat_obj['name_'+_this.convertLocale()]!=_this.filter.location.value) return false;
                        return true;
                    });
                }else{
                    return this.sapJobs;
                }
            },
        },
        methods:{
            viewJob(jobItem){
                this.currJobItem=jobItem;
                this.$refs.subpage.showSubpage();
            },
            updateJobs(e){
                // console.log(e.target.dataset.filterValue);
                this.filter[e.target.dataset.filter].value=e.target.dataset.filterValue;
                document.querySelector('.masonry-filter').classList.remove('active');
            },
            subpageActioned(action){
                // console.log('subpageActioned',action);
                if(action==='apply'){
                    window.open(`https://careers.sinbon.com/job/${this.currJobItem.jobReqLocale.results[0].externalTitle}/${this.currJobItem.jobReqId}-${this.convertLocale()}`, "_blank");
                }
            },
            convertLocale(){
                if(this.$i18n.locale==='cn'){
                    return 'zh_CN';
                }else if(this.$i18n.locale==='tw'){
                    return 'zh_TW';
                }else{
                    return 'en_US';
                }
            },
            fetchInfo:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrrecruit/info',
                })
                .then(function (response) {
                    _this.info=response.data.rows[0].description;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            
            fetchSap(){
                var _this=this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    //
                    url: 'sap?url='+encodeURI('JobRequisition?$expand='+
                    'jobReqLocale,'+
                    // 'cust_Section_obj,'+
                    // 'cust_SubDivision_obj,'+
                    // 'department_obj,'+
                    // 'location_obj,'+
                    // 'location_obj/addressNavDEFLT,'+
                    // 'location_obj/locationGroupNav,'+
                    // 'location_obj/custSiteFlxNav,'+
                    // 'location_obj/custSiteFlxNav/cust_location,'+//array(0)
                    // 'location_obj/descriptionTranslationNav,'+//null
                    // 'location_obj/nameTranslationNav,'+//nameTranslationNav.value_zh_CN (职位地点bylocale)//太細，改用下面那個是城市
                    'sfstd_jobLocationShortFormat_obj,'+//sfstd_jobLocationShortFormat_obj.value_zh_CN (职位城市bylocale)
                    // 'location_obj/nameTranslationNav/valueTranslationTextNav,'+//上面就可以了
                    // 'location_obj/nameTranslationNav/wfRequestNav,'+//array(0)
                    // 'status,'+
                    'mfield1/picklistLabels,mfield2/picklistLabels,'+
                    // 'cust_jobCategory,'+
                    'cust_jobCategory/picklistLabels,'+//职位类别
                    'jpSkill/picklistLabels,engSkill/picklistLabels,'+//filterArrByLocale
                    // 'spnSkill/picklistLabels,otherLangSkill/picklistLabels,'+//Array(0)
                    // 'partFullTime,'+
                    'partFullTime/picklistLabels,'+//filterArrByLocale 正式/临时
                    // 'travel,'+
                    'travel/picklistLabels,'+//filterArrByLocale 出差外派
                    'workExperience/picklistLabels'//filterArrByLocale 工作年资
                ),
                })
                .then(function (response) {
                    var _sapJobData=response.data.detail.d.results;
                    var _sapJobTemp=[];
                    // 先針對語系過濾
                    for(var k in _sapJobData){
                        // zh_CN,zh_TW,en_US
                        if(_this.$i18n.locale==='cn'){
                            if(_sapJobData[k].defaultLanguage==='zh_CN'){
                                // isDraft!=?
                                // internalStatus!="Pre-approved"
                                // intranetPosting!=false
                                // corporatePosting!=false
                                // deleted!="Deleted"
                                if(_sapJobData[k].isDraft!=true&&_sapJobData[k].deleted!="Deleted"&&_sapJobData[k].internalStatus!="Pre-approved"&&_sapJobData[k].intranetPosting!=false&&_sapJobData[k].coporatePosting!=false){
                                    _sapJobTemp.push(_sapJobData[k]);
                                }
                            }
                        }else if(_this.$i18n.locale==='tw'){
                            if(_sapJobData[k].defaultLanguage==='zh_TW'){
                                if(_sapJobData[k].isDraft!=true&&_sapJobData[k].deleted!="Deleted"&&_sapJobData[k].internalStatus!="Pre-approved"&&_sapJobData[k].intranetPosting!=false&&_sapJobData[k].coporatePosting!=false){
                                    _sapJobTemp.push(_sapJobData[k]);
                                }
                            }
                        }else{
                            if(_sapJobData[k].defaultLanguage==='en_US'){
                                if(_sapJobData[k].isDraft!=true&&_sapJobData[k].deleted!="Deleted"&&_sapJobData[k].internalStatus!="Pre-approved"&&_sapJobData[k].intranetPosting!=false&&_sapJobData[k].coporatePosting!=false){
                                    _sapJobTemp.push(_sapJobData[k]);
                                }
                            }
                        }
                    }
                    for(var k in _sapJobTemp){
                        var _filterTypeValue=_this.filterArrByLocale(_sapJobTemp[k].partFullTime.results[0].picklistLabels.results,'locale',_this.$i18n.locale)['label'];
                        if(_this.filter.type.options.indexOf(_filterTypeValue)===-1) _this.filter.type.options.push(_filterTypeValue);

                        var _filterCategoryValue=_this.filterArrByLocale(_sapJobTemp[k].cust_jobCategory.results[0].picklistLabels.results,'locale',_this.$i18n.locale)['label'];
                        if(_this.filter.category.options.indexOf(_filterCategoryValue)===-1) _this.filter.category.options.push(_filterCategoryValue);

                        var _filterLocationValue=_sapJobTemp[k].sfstd_jobLocationShortFormat_obj['name_'+_this.convertLocale()];
                        if(_this.filter.location.options.indexOf(_filterLocationValue)===-1) _this.filter.location.options.push(_filterLocationValue);
                    }

                    _this.sapJobs=_sapJobTemp;
                    // console.log('sap jobReqQuestion',_this.sapJobs);
                    _this.sapJobLoaded=true;
                    _this.$toggleLoading(false);


                    _this.$nextTick(function(){
                        document.querySelector('.masonry-filter-header a').addEventListener('click',function(e){
                            e.preventDefault();
                            document.querySelector('.masonry-filter').classList.add('active');
                        });
                        document.querySelector('.masonry-filter-bg').addEventListener('click',function(e){
                            document.querySelector('.masonry-filter').classList.remove('active');
                        });
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            
            filterArrByLocale(arr,key,i18nLocale){
                var _val;
                if(i18nLocale==='cn'){
                    _val='zh_CN';
                }else if(i18nLocale==='tw'){
                    _val='zh_TW';
                }else{
                    _val='en_US';
                }
                for(var i=0;i<arr.length;i++){
                    if(arr[i][key]===_val) return arr[i];
                }
            },
        },
        created(){
            this.$toggleLoading(true);
            this.fetchInfo();
            this.fetchSap();
            setMetaInfo( this, this.$t('page_title_career_job'), undefined, undefined, undefined,false,true );
        },
    };
</script>