<template>
    <div class="subpage" ref="subpage">
        <div class="subpage__header">
            <div class="container">
                <a class="subpage__back" href="#" @click.prevent="closeSubpage">
                    <span class="subpage__arrow">&#8592;</span> {{ $t('subpage_back') }}
                </a>
                <button v-if="action" @click.prevent="actionClicked" class="subpage__action btn btn--primary px-4 m-0">{{action.text}}</button>
            </div>
            
        </div>
        <slot></slot>
    </div>
</template>


<style>
.subpage{
    position: fixed;
    top:0;
    left: 100%;
    /* left: 275px; */
    width: calc(100% - 275px);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    z-index: 100;
    transition: all 0.3s;
    &.active{
        left: 275px;
    }
}
.subpage__con{
    /* padding-top: 5%;
    padding-bottom: 5%; */
    padding: 3em 0;
}
/* .subpage__jobDetail{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
    p{
        flex: 0 0 50%;
        margin-bottom: 0.5em;
    }
} */
@media screen and (max-width: 991px){
    .subpage{
        width: 100%;
        height: calc(100% - 47px);
        top:52px;
        &.active{
            left: 0;
        }
    }
}
/* @media screen and (max-width: 767px){
    .subpage__jobDetail{
        p{
            flex: 0 0 100%;
        }
    }
} */
.subpage__header{
    /* padding-bottom: 0!important; */
    /* width:90%;
    max-width: 90%;
    margin: auto; */
    /* padding: 1.5em 0; */
    position: sticky;
    top:0;
    z-index: 99;
    background: white;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    height: 60px;
    /* @media screen and (min-width: 1000px){
        width:90%;
        max-width: 90%;
        margin: auto;
        padding: 2em 15px 1em 15px;
    } */
    /* @media screen and (max-width: 1000px){
        width: 100%;
        max-width: 100%;
        padding: 2em var(--layout-container-x-padding);
    } */
     .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
     }
}
.subpage__arrow{
    display: inline-block;
    margin-right: 5px;
}
</style>



<script>
export default {
    props: {
        backHook: Function,
        action: Object
    },
    methods: {
        showSubpage(){
            this.$refs.subpage.classList.add('active');
        },
        closeSubpage(){
            this.$refs.subpage.classList.remove('active');
            if(this.backHook) this.backHook();
        },
        actionClicked(){
            this.$emit('action',this.action.action);
        }
    },
}
</script>