<template>
  <div id="footer">
    <section v-if="showEdm" class="space--sm bg--dark">
      <div class="container px-0">
        <div class="text-center">
          <h3>
            <!-- 訂閱我們的電子報，隨時掌握最新動態 -->
            {{ $t('home_subscribe_title') }}
          </h3>
          <a href="#" class="inner-link btn btn--primary btn--md" data-notification-link="edmForm">
          <!-- <a href="#" class="inner-link btn btn--primary btn--md" @click.prevent="$showNotification('.edmForm')"> -->
            <!-- 訂閱電子報 -->
            <span class="btn__text">{{ $t('home_subscribe_send') }}</span>
          </a>
        </div>
      </div>
      <!-- <form @submit.prevent="subscribe" class="cta cta--horizontal text-center">
          <div class="row mb-3">
            <div class="col text-center">
              <h3>
                {{ $t('home_subscribe_title') }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-7 offset-sm-1 col-lg-5 offset-lg-2 footer-subscribe-input">
              <input
                v-model="email"
                type="email"
                :placeholder="$t('home_subscribe_placeholder')/*請輸入電子郵件*/"
                class="validate-required validate-email"
              >
            </div>
            <div class="col-sm-3 col-lg-3 text-center">
              <input
                type="submit"
                :value="$t('home_subscribe_send')/*訂閱電子報*/"
                class="btn btn--primary type--uppercase inner-link"
              >
            </div>
          </div>
        </form> -->
    </section>
    
    <footer class="space--sm footer-2" style="border-top:1px solid #eee;">
      <div class="container px-0">
        <div class="text-center">
          <a class="_footer-socialmedia__btn" href="https://www.facebook.com/SINBONELECTRONICS/" target="_blank">
            <i class="socicon-facebook"></i>
          </a>
          <a class="_footer-socialmedia__btn" href="https://tw.linkedin.com/company/sinbon-electronics" target="_blank">
            <i class="socicon-linkedin"></i>
          </a>
          <a class="_footer-socialmedia__btn" href="https://www.youtube.com/channel/UC1GexrM8y-RWMDWmRa7rbSg" target="_blank">
            <i class="socicon-youtube"></i>
          </a>
        </div>
        <div class="d-flex flex-column flex-md-row justify-content-center align-items-center mt-3 mt-sm-5">
          <div class="footer__links pr-2 pl-2">
            <!-- 網站地圖 -->
            <router-link :to="{ name: 'sitemap' }" class="type--fine-print"><span>{{ $t('nav_sitemap') }}</span></router-link>
            <!-- 使用條款 -->
            <router-link :to="{ name: 'terms' }" class="type--fine-print"><span>{{ $t('nav_terms') }}</span></router-link>
            <!-- 隱私權政策 -->
            <router-link :to="{ name: 'pravicy-policy' }" class="type--fine-print"><span>{{ $t('nav_privacy') }}</span></router-link>
            <!-- 隱私設定 -->
            <a href="#" @click.prevent="showCookieSetting" class="type--fine-print"><span>{{ $t('nav_privacy_setting') }}</span></a>
            <!-- 信邦人信箱 -->
            <a href="https://webmail.sinbon.com" class="type--fine-print" target="_blank"><span>{{ $t('nav_webmail') }}</span></a>
          </div>
          <div class="pr-2 pl-2 mt-2 mt-md-0">
            <!-- ©SINBON Electronics Co., Ltd. All rights reserved. -->
            <span class="type--fine-print">{{ $t('nav_copyright') }}</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  methods:{
    showCookieSetting(){
      document.querySelector('.cookieSetting').classList.add('active');
    },
  },
  computed: {
    showEdm() {
      if(this.$route.name=='esg-page') return false;
      else return true;
    },
  },
};
</script>