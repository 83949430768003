<template>
	<div class="about">
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="People in different ethnicity giving each other a high five" :src="$data.siteUrl + '/img/banner-about.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('about_banner_title')">夢想、快樂、<br/>持續創新的卓越企業</h1>
                        <p v-html="$t('about_banner_p')">這些價值導引我們的經營運作，並時時提醒我們成功的要素為何</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_about')">關於信邦</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <div class="switchable__text">
                            <h2 class="h2" v-html="$t('about_philosophy_title')">為客戶提供更廣泛<br/>的高附加價值產品服務</h2>
                            <hr class="short">
                            <p class="sub-lead" v-html="$t('about_philosophy_p_1')">信邦成立於西元1989年，為提供電子零件整合設計與製造服務的領導廠商，產品應用範圍涵蓋資料擷取、電腦週邊和其他電子產品。順應科技產業的快速發展和日益激增的市場需求，信邦致力於產品研發和強化員工專業知識，以求達到客戶的技術要求，並提供客戶滿意且完整的解決方案。經過這幾年的努力，信邦成功轉型為提供完全解決方案的製造商，並且跨入了汽車電子、航太、工業控制、醫療器材和消費性電子產品領域，為客戶提供更廣泛的高附加價值產品服務。</p>
                            <p class="sub-lead" v-html="$t('about_philosophy_p_2')">信邦於台灣、中國、日本、英國、德國、匈牙利和美國等地廣佈據點，架起市場和商業資訊可相互整合及交換的資源網路。透過此一網路，信邦可以快速回應客戶的需求、加速訂單的處理、縮短生產與交貨時程、降低設計與開發成本，提供客戶無國界和無時差的服務與支援。此外，從全球原物料搜詢採購服務、產品接單、打樣與製造到售後服務，信邦提供了客戶最愉悅的合作環境，並且可將客戶對產品的初步構想轉化為高品質、高效能的產品。</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-center bg--secondary">
            <div class="container">
                <div class="row same-height-col">
                    <div class="col-md-6">
                        <div class="feature feature-3 boxed boxed--lg boxed--border">
                            <img class="multiply" :src="$data.siteUrl + '/img/about-icon-1.png'" />
                            <h4 v-html="$t('about_feature_1')">培育人才、凝聚共識</h4>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="feature feature-3 boxed boxed--lg boxed--border">
                            <img class="multiply" :src="$data.siteUrl + '/img/about-icon-2.png'" />
                            <h4 v-html="$t('about_feature_2')">雙贏的原則下<br/>提供客戶設計整合及服務、共創產品價值</h4>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="feature feature-3 boxed boxed--lg boxed--border">
                            <img class="multiply" :src="$data.siteUrl + '/img/about-icon-3.png'" />
                            <h4 v-html="$t('about_feature_3')">創造員工與股東最大利益<br/>共同分享公司的成果</h4>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="feature feature-3 boxed boxed--lg boxed--border">
                            <img class="multiply" :src="$data.siteUrl + '/img/about-icon-4.png'" />
                            <h4 v-html="$t('about_feature_4')">善盡社會公民的責任</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="milestone">
            <div class="container">
                <h3 v-html="$t('about_milestone')">重大記事</h3>
                <div class="swiperContainer">
                    <swiper class="swiper" :options="swiperOption">
                        <swiperSlide v-for="(list,name) in items">
                            <div class="swiperCard">
                                <h4 class="milestone__year">{{ name }}</h4>
                                <ul class="milestone__list">
                                    <li class="milestone__item" v-for="item in list">
                                        <div>
                                            <span class="milestone__month">{{item.month}}</span>
                                            <span class="milestone__category">{{item.category_name}}</span>
                                        </div>
                                        <p class="milestone__content" v-html="item.description"></p>
                                    </li>
                                </ul>
                            </div>
                        </swiperSlide>
                    </swiper>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </div>
                
                <!-- <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="process-1" v-if="items">
                            <div class="process__item" v-for="item,key in items.rows">
                                <h4 class="h4 mb-0">{{item.article_date}}</h4>
                                <p class="sub-lead">{{item.description}}</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>

        <section class="bg--secondary">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-left"  v-html="$t('about_quality')">品質政策</h3>
                        <p  v-html="$t('about_quality_des_1')">提供客戶最高品質的產品為信邦的首要之務。我們遵守業界最嚴謹的要求和標準，以確保可以如期地完成設計、並將品質優良的產品交給客戶。信邦會持續加強製造技術、加速產品交期，來符合客戶對高品質產品的需求。</p>
                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <div class="row  py-3">
                    <div class="col-lg-4">
                        <div class="text-block py-1 mb-0">
                            <h4 class="font-weight-bold mb-0">SINBON Taiwan</h4>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 9001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div>
                            <!-- <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div> -->
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 13485</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 45001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">UL Certification</p>
                            </div>
                            <!-- <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IECQ Certification</p>
                            </div> -->
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">AS 9100</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IATF 16949</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-610</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-620</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">SA 8000</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ANSI/ESD S20.20</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">FDA</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row  py-3">
                    <div class="col-lg-4">
                        <div class="text-block py-1 mb-0">
                            <h4 class="font-weight-bold mb-0">SINBON Beijing, China</h4>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 9001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 45001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">UL Certification</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-610</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-620</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row  py-3">
                    <div class="col-lg-4">
                        <div class="text-block py-1 mb-0">
                            <h4 class="font-weight-bold mb-0">SINBON Jiangyin, China</h4>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 9001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 13485</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">UL Certification</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">AS 9100C</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IATF 16949</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">MedAccred</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-610</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-620</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ANSI/ESD S20.20</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">FDA</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 17025</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 45001</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row  py-3">
                    <div class="col-lg-4">
                        <div class="text-block py-1 mb-0">
                            <h4 class="font-weight-bold mb-0">SINBON Tongcheng, China</h4>
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 9001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 13485</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">UL Certification</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-620</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IATF 16949</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 45001</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="row  py-3">
                    <div class="col-lg-4">
                        <div class="text-block py-1 mb-0">
                            <h4 class="font-weight-bold mb-0">SINBON Ohio, USA</h4>
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 9001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 13485</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">UL Certification</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-610</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-620</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <hr/>
                <div class="row  py-3">
                    <div class="col-lg-4">
                        <div class="text-block py-1 mb-0">
                            <h4 class="font-weight-bold mb-0">SINBON Hungary</h4>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 9001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14001</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 14064-1</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">ISO 13485</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">UL Certification</p>
                            </div>
                            <div class="col-12 col-sm-6 py-1">
                                <p class="mb-0">IPC-620(class 2)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3 v-html="$t('about_quality_title_1')">關於 ISO 45001</h3>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row justify-content-around">
                        <div class="col-md-6 pl-sd-0 py-3">
                            <img class="border--round box-shadow-wide" :src="$data.siteUrl + '/img/about-1.jpg'" />
                        </div>
                        <div class="col-md-6 col-lg-5 py-3">
                            <div class="switchable__text">
                                <h4 class=""  v-html="$t('about_quality_declaration_1')">安全衛生宣言</h4>
                                <p  v-html="$t('about_quality_declaration_2')">安全至上 / 快樂工作 / 健康幸福 / 社會和諧</p>
                            </div>
                            <div class="switchable__text mt-5">
                                <h4 class="" v-html="$t('about_quality_commitment_1')">安全衛生承諾</h4>
                                <p v-html="$t('about_quality_commitment_2')">本公司基於維護員工健康及工作安全，減少因生產活動對員工所造成之影響致力於持續不斷的改善及傷害預防，提供安全工作環境積極推行安全與健康宣導遵循政府法令規章符合各項安全標準，訂定安衛目標以展現改善績效建立安衛管理系統，透過內外部溝通宣導向社會及利害關係者公開、運作審查達到永續經營。</p>
                            </div>
                        </div>
                    </div>
                </div>
            
        </section>

        <channel-box v-bind:blocks="['solution','contact','global-location']"/>
    </div>
</template>
<style lang="less">
.milestone{
    background: lighten(#7ca2c9,30);
}
.swiper-wrapper{
    transition: all 0.5s ease-in-out!important;
}
.same-height-col{
    img{
        max-width:80px;
        max-height: 80px;
        margin:0 auto;
    }
    .boxed{
        justify-content: center!important;
    }
    @media screen and (min-width: 768px) {
        img{
            margin-bottom:20px;
        }
        .boxed{
            justify-content: flex-start!important;
        }
    }
}
</style>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js"

    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'

	export default {
        data:function(){
            return {
                items:null,
                metaData:{},
                swiperOption: {
                    spaceBetween: 20, // 图片之间的间距
                    // centeredSlides: true, // 居中还是从图1开始
                    slidesPerView: 1.1, // 一屏幕显示几个? 数字或者默认  auto 自动。
                    notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
                    // loop: true, // 循环吗
                    initialSlide: 0, // 从第几个开始
                    speed: 800, // 滑动时候动画的速度
                    // autoplay: {
                    //   delay: 5000, // 等5秒下一个
                    //   disableOnInteraction: false // 中间滑动一下，取消自动吗？
                    // },
                    // pagination: {
                    //   el: '.swiper-pagination',
                    //   clickable: true
                    // }, // 下按钮
                    // paginationClickable: true, // 下面按钮让点吗
                    navigation: {
                      prevEl: '.swiper-button-prev', // 左侧按钮
                      nextEl: '.swiper-button-next' // 右侧按钮
                    },
                    breakpoints: {
                        720: {
                            slidesPerView: 3,
                            // spaceBetween: 40
                        },
                        // when window width is >= 480px
                        // 480: {
                        //     slidesPerView: 3,
                        //     spaceBetween: 30
                        // },
                        // when window width is >= 640px
                        
                    },
                    on:{
                        transitionEnd: function(){
                            console.log('swiper transitionEnd');
                            var _h
                            if(window.innerWidth<720){
                                _h=$('.swiper-slide:eq('+this.activeIndex+') .swiperCard').height();
                            }else{
                                _h=Math.max($('.swiper-slide:eq('+this.activeIndex+') .swiperCard').height(),$('.swiper-slide:eq('+(this.activeIndex+1)+') .swiperCard').height(),$('.swiper-slide:eq('+(this.activeIndex+2)+') .swiperCard').height());
                            }
                            $('.swiper-wrapper').height(_h+20);
                        },
                        init: function(){
                            console.log('swiper init');
                            setTimeout(()=>{
                                this.emit('transitionEnd');
                            },1000);
                        },
                    },
                    
                    //  effect: 'fade', // 渐入效果
                    // watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
                    // watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
                    // autoHeight: true  // 图片高度自适应
                },
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrmilestone/items',
                    
                })
                    .then(function (response) {
                        var _data=response.data.rows;
                        _data.reverse();
                        var _ms={};
                        var _monthText=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                        for(var k in _data){
                            var _year=_data[k].article_date.substr(0,4);
                            var _month=_monthText[Number(_data[k].article_date.substr(5,2))-1];
                            if(!_ms[_year]){
                                _ms[_year]=[];
                            }
                            _data[k].month=_month;
                            _ms[_year].push(_data[k]);
                        }
                        _this.items=_ms;
                        // _this.$window.initAll();
                        _this.$toggleLoading(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
        created() {
            this.fetchItems();
            setMetaInfo( this, this.$t('page_title_about'), undefined, 'All about SINBON. We provide customized services, flexiable production, worldwide location, and mutiple certifications', undefined,false,true );
        },
        components: {
            "channel-box": channelBox,
            swiper,
            swiperSlide
        },
    };

</script>